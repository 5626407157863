body {
  background-color: $dark;
}

.char-img__bg {
  width:-webkit-fill-available;;
  text-align:center;
  overflow:hidden;
  height: 80%;
}

.char-img-list__bg {
  width:-webkit-fill-available;;
  text-align:center;
  overflow:hidden;
  border-bottom: 0.3rem solid $highlight;
  background-color: rgba(black,0.3);
  transition: 200ms;
}

.char-img-list__bg:hover, .char-img-list__active {
  background-color: rgba($highlight,0.8);
  transition: 200ms;
}

.char-img {
  max-width:none;
  margin:0 -100%;
  width: 13rem;
}

.sprite-img {
  max-width:none;
  width: -webkit-fill-available;
}

.char-title {
  background: linear-gradient(to left,
    rgba(black,0),
    $highlight,
  );
  border-radius: 0.2rem;
}

.side-menu-bg {
  background: linear-gradient(to left,
    rgba(black,0),
    rgba($highlight,0.6),
  );
}
.side-menu-bg:hover, .side-menu-bg-active {
  background: linear-gradient(to left,
    rgba(black,0),
    rgba($highlight,1),
  );
}

.char-skill__bg {
  width: 100%;
  background-size: Cover;
  background-repeat: no-repeat;
  padding-bottom: 0.25rem;
}
.char-faction__bg {
  width: 100%;
  background-size: Cover;
  background-repeat: no-repeat;
  padding-bottom: 0.25rem;
  @media only screen and (min-width: $md) {
    background-size: contain;
  }
}
.cfgb-Thunder, .cfgb-Tank, .cfgb-Wrestling{
  background-image: url("../Assets/bg/faction-bg1.png");
}
.cfgb-Agility,.cfgb-Military,.cfgb-Support {
background-image: url("../Assets/bg/faction-bg2.png");
}
.cfgb-Anasatsuken, .cfgb-Infernal, .cfgb-Raging {
  background-image: url("../Assets/bg/faction-bg4.png");
}
.cfgb-Assassin, .cfgb-Wind, .cfgb-Ninjutsu, .cfgb-Tech {
  background-image: url("../Assets/bg/faction-bg5.png");
}
.cfgb-Secret{
  background-image: url("../Assets/bg/faction-bg7.png");
}
.cfgb-Master, .cfgb-Legendary {
  background-image: url("../Assets/bg/faction-bg8.png");
}
.cfgb-Attack, .cfgb-Power, .cfgb-Flame, .cfgb-Budo {
  background-image: url("../Assets/bg/faction-bg9.png");
}
.cfgb-Balanced, .cfgb-Savage {
  background-image: url("../Assets/bg/faction-bg10.png");
}

.char-faction__img {
  width: 2rem;
  height: 2rem;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.2rem;
}

.char-faction__label {
  text-transform: uppercase;
  font-size: 0.7rem;
}

.char-faction__txt {
  font-size: 1.2rem;
  margin-top: 0.1rem;
  margin-left: 0.1rem;
  text-shadow: 2px 2px 6px rgba(0,0,0,0.9);
}

.char-bond__txt {
  font-size: 1.3rem;
  margin-top: 0.1rem;
  margin-left: -2rem;
  text-shadow: 6px 6px 10px rgba(0,0,0,0.9);
}

.fs__title {
  font-size: 1.3rem;
  text-shadow: 6px 6px 10px rgba(0,0,0,0.9);
}


.char-side-menu__txt {
  font-size: 1.6rem;
  margin-top: 0.1rem;
  margin-left: -3.2rem;
  text-shadow: 3px 3px 4px rgba(0,0,0,0.9);
}

.char-stat__place {
  font-weight: 400;
  font-size: 0.8rem;
  color: $highlight;
}

.char-stat__bg {
  background-color: rgba(0,0,0,0.6);
  border-radius: 0.4rem;
  // margin: 0.2rem;
  padding-top: 0.1rem;
}

.after-char-main {
  margin-top: -70px!important;
}

.skill-label{
  font-size: 0.8rem;
  letter-spacing: .15rem;
  font-weight: bold;
  margin: none;
  text-transform: uppercase;
  margin-bottom: -0.5rem;
  margin-left: 0.2rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,1);
}
.skl-Super {color: #fcf17b;}
.skl-Passive {color: #96e86a;}
.skl-Combo {color: #4ce2c5;}
.skl-Exclusive {color: #c677ee;}

.green-txt {color: #96e86a;}

.skill-img-Super {width: 6rem;}
.skill-img-Combo {
  width: 4.5rem;
  margin: 0 0.4rem 0 0.8rem;
}
.skill-img-Passive, .skill-img-Exclusive {
  width: 4.5rem;
  margin: 0 0.5rem;
}
.combo-position-img{
  width: 2.2rem;
  height: 3.5rem;
  z-index: 2;
  margin-left: -5.8rem;
  margin-right: 4rem;
}

.skill-target {
  background-color: $skilltarget;
  border-radius: 0.25rem;
  text-align: center;
  color: black;
  font-weight: bold;
  width: fit-content;
  padding: 0 0.75rem;
}

.skbg-Super {
  background-image: url("../Assets/bg/skill-bg1.png");
}
.skbg-Combo, .skbg-Passive {
  background-image: url("../Assets/bg/skill-bg2.png");
  padding-left: 0.5rem;
}
.skbg-Exclusive {
  background-image: url("../Assets/bg/skill-bg3.png");
  padding-left: 0.5rem;
}

.skill-txt__bg {
  background-color: rgba($gray,0.7);
  border: 0.1rem solid #777165;
  border-top: none;
}
.skill-txt__bg-bt {
  border-top: 0.1rem solid #777165;
}

.skill-txt {
  font-family: 'Roboto', sans-serif;
  text-shadow: 2px 2px 6px rgba(0,0,0,0.9);
}

.desc-txt {
  font-size: 0.9rem;
}

.vl {
  border-left: 0.2rem solid $green;
  border-radius: 2.5rem;
  margin: 0.2rem 0.5rem 0.2rem 0.5rem;
}

.lv-color {
  color: gray;
  text-shadow: none;
}

.bonds-label {
  font-family: 'Roboto', sans-serif;
  text-shadow: 2px 2px 6px rgba(0,0,0,0.9);
  font-size: 0.8rem;
}

.bond-A{color: #b354b8;}
.bond-S{color: #af8851;}
.bond-SS{color: #881a49;}

.bond-img {
  width: 8rem;
}

.f-spirit-bg {
  background-color: rgba(#1c192a,0.4);
  border: 0.1rem solid #777165;
  border-radius: 0.2rem;
}

.f-spirit-stat__bg {
  background-color: #2e2c37;
  width: 100%;
}

.f-spirit-esxlusive-txt {
  margin-bottom: 0.25rem;
  margin-top: -0.3rem;
}


.charlist-item-img {
  width: 10rem;
}

.charlist-item-img-bg {
  width: 7rem;
  margin: 0 0rem;
  @media only screen and (min-width: $md) {
    margin: 0 1rem;
  }
}

.skill-color-blue {color: #2273ff}
.skill-color-orange {color: #dc5800;}

.char-list__txt {
  transform: rotate(-90deg);
  color: white;
  text-shadow: 3px 3px 4px rgba(0,0,0,1);
  font-size: 2rem;
  margin-top: -8.6rem;
  margin-right: -4.7rem;
  margin-bottom: 0.5rem;
  background: linear-gradient(to left,
    rgba(blue, 0),
    rgba($highlight,0.9),
  );
  padding-left: 0.255rem;
  @media only screen and (min-width: $md) {
    margin-top: -9.6rem;
    margin-bottom: 0.5rem;
  }
}

.big-name {
  margin-top: -11rem;
  margin-left: -3.8rem;
  margin-bottom: 9rem;
  @media only screen and (min-width: $md) {
    margin-left: -2.75rem;
    margin-bottom: 10rem;
  }
}

.big-name-div {
  margin-right: 2rem!important;
}
.effect-img{
  width: 1.2rem;
  height: 1.2rem;
}

.char-subtitle-bg {
  background-color: rgba($color: black, $alpha: 0.7);
  border-radius: 2rem;
}

.char-list-faction {
  width: 2rem;
  height: 2rem;
  margin-top: -4.5rem;
  margin-left: 0.1rem;
  @media only screen and (min-width: $md) {
    margin-left: 1.1rem;
  }
}

.new-charlist{
  color: greenyellow;
  margin-bottom: -1.3rem;
  margin-right: 1.4rem;
  text-shadow: 2px 2px 6px rgba(0,0,0,0.9);
  text-align: end;
  z-index: 20;
  position: relative
}

.puzzle-video {
  width: -webkit-fill-available;
}

.puzzle-video > iframe {
  width: -webkit-fill-available;
}


.car-icon {
  height: fit-content;
}

.reward-img {
  width: 2rem;
  margin-bottom: 0.5rem;
}