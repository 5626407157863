@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: "Ardela Edge";
  src: url("../Assets/ArdelaEdge.woff") format('woff');
}


$dark: #1D1E22;
$dark2: #222631;
$med: #2d2c3c;

$gray: #1d1b1c;
$highlight: #D82A2E;
$skilltarget: #febf26;
$green: #369e4e;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;