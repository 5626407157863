.list-char-title {
  margin-right: 0.25rem;
  margin-top: -0.25rem;
}

.list-char-img {
  width: 6rem;
}

.list-char-div {
  transition: 200ms;
}

.list-char-div-active {
  background-color: $highlight;
  border-radius: 0.25rem;
  transition: 200ms;
}

.list-char-div:hover {
  @media only screen and (min-width: $md) {
    background-color: $highlight;
    border-radius: 0.25rem;
    transition: 200ms;
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-combo-sim {
  background-color: darkblue!important;
  color: white!important;
  border-radius: 0.5rem!important;
}

.combosim-super-img{
  width: 2.8rem;
  height: 2.2rem;
  z-index: 2;
  margin-left: -5.8rem;
  margin-right: 4rem;
}
.combosim-combo-h {
  transition: 200ms;
  cursor: pointer;
}
.combosim-combo-h:hover, .combosim-combo-h-active {
  background-color: $highlight;
  transition: 200ms;
  border-radius: 0.2rem;
}

.combosim-combo-h-deactive {
  opacity: 0.7;
  // cursor:default!important;
  cursor: not-allowed;
}
.combosim-combo-h-deactive:hover {
  background-color: inherit!important;
}