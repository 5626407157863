.home-row {
  --bs-gutter-x: 0!important;
  margin-right: 0;
  margin-left: 0;
}

.desktop-char-row {
  background-color: rgba(black, 0.1);
  border-radius: 0.5rem;
  @media only screen and (min-width: $lg) {
    background-color: rgba(black, 0.3);
  }
}

.desktop-list-row {
  background-color: rgba(black, 0.3);
  border-radius: 0 0 0.25rem 0.25rem;
}

.menu-button {
  color: white!important;
  text-align: center;
  font-size: 1.2rem;
  padding: 0 0.75rem;
  border-bottom: 0.1rem solid $med;
  transition: 200ms;
  font-weight: bolder!important;
  border-radius: 0.1rem;
  @media only screen and (min-width: $lg) {
    font-size: 1.6rem;
  }
  margin-left: 0.2rem;
}

.menu-button:hover, .menu-button-active  {
  background-color: $highlight;
  transition: 200ms;
}

.menu-bg {
  position: fixed;
  z-index: 9;
  background-color: black;
  box-shadow: 0px 10px 10px -6px rgba(0,0,0,0.5);
}

.search-overflow {
  overflow: auto;
}

.search-overflow::-webkit-scrollbar {
  width: 0.4rem;
  border-radius: 10px;
}

.search-overflow::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: $highlight;
  border-radius: 10px;
}

.max-vh {
  height: 100vh;
}

.list-vh {
  height: 95vh;
}

.navbar {
  color: white!important;
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0!important;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e);
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.logo-img{
  width: 8rem;
}

.boss-event-img{
  width: 3.5rem;
}