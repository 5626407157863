.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}

a {
  text-decoration: none!important;
}

p {
  margin-top: 0;
  margin-bottom: 0.25rem!important;
}

.border-none {
  border: none!important;
}

.webkit-fill {
  width: -webkit-fill-available;
}

.text-right {
  text-align: right!important;
}

.font-weight-light {
  font-weight: lighter!important;
}

.custom-row {
  --bs-gutter-x: 0rem!important;
}

hr {
  border: 0;
  border-top: 1px solid;
  color: inherit;
  margin: 0.3rem 0;
  opacity: .25;
}