@import './settings';
@import './base';
@import './char';
@import './home';
@import './comboSim';
@import './filter';

.App {
  color: white;
  background-color: $dark;
  font-family: 'Oswald', sans-serif;
  background-image: url('../Assets/bg2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.ardela {
  font-family: 'Ardela Edge', sans-serif;
  font-weight: bolder;
  font-style: italic;
  text-transform: uppercase;
}

.roboto {
  font-family: 'Roboto', sans-serif;
  text-shadow: 2px 2px 6px rgba(0,0,0,0.9);
}

.link {
  color: inherit!important;
}

.margin-main {
  padding-top: 4.25rem;
}

.color-highlight {
  color: $highlight!important;
}

.mb-0{
  margin-bottom: 0rem!important;
}
.char-list-sm-title{
  margin-top: 0.4rem;
  margin-right: 0.3rem;
  font-weight: bolder;
}

.font-bolder {
  font-weight: bolder;
}